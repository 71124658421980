<template>
  <article class="workReport" v-loading="loading">

    <div class="title-container">
      <div class="title">作业报告</div>
    </div>

    <header class="header">
      <div class="column">
        <div class="left">
          <el-select class="col" v-model="classId" popper-class="class-popper" placeholder="班级">
            <el-option v-for="(item, index) in classList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>
          <el-select class="col last" v-model="dateType" popper-class="time-popper" placeholder="时间">
            <el-option v-for="(item, index) in dateTypeList" :key="index" :label="item.name" :value="item.id"/>
          </el-select>

          <ChooseTime :date-type="dateType" @chooseTime="chooseTime"/>

        </div>
        <div class="right">
          <el-button type="primary" class="btn" @click="searchData">查询</el-button>
        </div>
        <!--   TODO 暂时屏蔽     <LinkButton @click.native="gradeWorkReport" v-if="superAdmin === '2'">查看年级作业报告</LinkButton>-->
      </div>
    </header>

    <Report
      ref="report"
      :classId="classId"
      :dateType="dateType"
      :startTime="startTime"
      :endTime="endTime"
      :isPrevent="isPrevent"
    />

  </article>
</template>

<script>
import Report from './components/Report'
import ChooseTime from "@/components/common/ChooseTime";

export default {
  name: 'workReport',
  components: {
    Report,
    ChooseTime
  },
  data() {
    return {
      loading: false,
      isPrevent: false,
      classList: [],
      classId: '',
      className: '',
      timeList: [],
      dateTypeList: [
        {id: 2, name: '按自然月查询'},
        {id: 1, name: '按自然周查询'},
        {id: 0, name: '自定义时间'}
      ],
      dateType: 2,
      startTime: '',
      endTime: '',
      superAdmin: 0
    }
  },
  created() {
    this.superAdmin = sessionStorage.getItem('superAdmin')
  },
  mounted() {
    this.getClass()
  },
  methods: {
    // 查询数据
    searchData() {
      this.$refs.report.resetView()
    },
    chooseTime(start, end) {
      this.startTime = start
      this.endTime = end
    },
    // 获取班级
    getClass() {
      this.$axios({
        method: 'post',
        url: this.$urls.gradeClassList,
        data: {
          category: 3
        }
      })
      .then((response) => {
        if (response?.state === '11') {
          const {data} = response
          if (data.length > 0) {
            this.classList = data
            this.classId = data[0].id
            this.className = data[0].name
            this.$store.commit('includeAdd', 'workReport')
            this.$nextTick(() => {
              this.isPrevent = true
            })
          }
        }
      })
    },
    gradeWorkReport() {
      this.$store.commit('includeDel', 'gradeWorkReport')
      this.$router.push({
        name: 'gradeWorkReport',
        query: {
          name: '查看年级作业报告'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.workReport-container {
  height: calc(100vh - 230px);
  overflow: auto;
}

.title-container {
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 28px;
    padding: 28px 0 28px 28px;
  }
}

.header {
  background-color: #fff;
  padding: 0 28px;

  .column {
    @include flex(space-between);
    height: 72px;
    font-size: 15px;

    .left {
      display: flex;
      align-items: center;
      position: relative;
    }

    .right {
      .btn {
        width: 100px;
        height: 32px;
        background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
        border-radius: 4px;

        &:hover {
          background: #309AF2;
        }
      }
    }

    .col {
      width: 140px;
      margin-right: 10px;

      &.el-select {
        ::v-deep .el-input__inner {
          color: #333333;
        }
      }
    }

    .col.last {
      margin-right: 0;
    }
  }
}

.el-select-dropdown__list {
  .el-select-dropdown__item {
    color: #333333;
    font-size: 15px;
    font-weight: 400;
    padding: 0 28px;

    &.hover {
      color: #000000;
    }

    &.selected {
      background-image: url("../../assets/right_arrow.png");
      background-repeat: no-repeat;
      background-position: 9% 50%;
    }
  }
}


</style>
